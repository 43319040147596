@import url('https://fonts.googleapis.com/css?family=Noto%20Sans:400,700');

*{
    margin: 0px;
    padding: 0px;
}

body{
    font-family: 'Noto Sans', sans-serif;
    background-color: #010118;
    opacity: 0.8;
    background-image: radial-gradient(#0e1359 0.5px, #010118 0.5px);
    background-size: 10px 10px;
}

.logo {
    width: 70px;
    height: 70px;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
    margin-left: 25px;
    margin-right: 25px;
}

a:link {
    text-decoration: none;
}

h1 {
    font-size: 2rem;
    letter-spacing: 0.9px;
}

.context {
    width: 100%;
    position: absolute;
    top: 50px;
}

.context h1{
    text-align: center;
    color: #fff;
    font-size: 40px;
    margin-top: 8px;
    margin-left: 25px;
    margin-right: 25px;
}

.title {
    margin-bottom: 30px;
}

.text {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #000;
}


.area{
    width: 100%;
    height:100vh;
}

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;

}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}

@media screen and (max-width: 860px) {

}

@media screen and (max-width: 710px) {
    .context h1 {
        margin-top: 10px;
        letter-spacing: 0.9px;
    }
}

@media screen and (max-width: 615px) {
    .context h1 {
        margin-top: 10px;
        letter-spacing: 0.9px;
    }
    .logo {
        margin-top: 12px;
        margin-left: 55px;
    }
    .header {
        margin-left: 55px;
        margin-right: 55px;
    }
}

@media screen and (max-width: 515px) {
    .context h1 {
        margin-top: 15px;
        font-size: 1.7rem;
        letter-spacing: 0.9px;
    }
    .logo {
        margin-top: 12px;
        margin-left: 15px;
    }
    .header {
        margin-left: 55px;
        margin-right: 55px;
    }
    .text {
        text-align: center;
        margin-top: 13px;
        margin-bottom: 13px;
        color: #000;
        font-size: 16px;
    }
}

@media screen and (max-width: 390px) {
    .context h1 {
        font-size: 1.5rem;
        letter-spacing: 0.9px;
    }
    .header {
        margin-left: 55px;
        margin-right: 55px;
    }
    .text {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
        color: #000;
        font-size: 14px;
    }
}

@media screen and (max-width: 290px) {
    h1 {
        font-size: 1rem;
        letter-spacing: 0.9px;
    }
    .text {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
        color: #000;
        font-size: 14px;
    }
}