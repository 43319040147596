.card {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 25px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.5px);
    -webkit-backdrop-filter: blur(9.5px);
    border: 1px solid rgba(255, 255, 255, 0.84);
    width: 800px;
    height: auto;
    margin: 5px auto;
}

@media screen and (max-width: 860px) {
    .card {
        width: 655px;
    }
}

@media screen and (max-width: 710px) {
    .card {
        width: 555px;
    }
}

@media screen and (max-width: 615px) {
    .card {
        width: 455px;
    }
}

@media screen and (max-width: 515px) {
    .card {
        width: 355px;
    }
}

@media screen and (max-width: 390px) {
    .card {
        width: 290px;
    }
}

@media screen and (max-width: 290px) {
    .card {
        width: 260px;
    }
}