.container {
    width: 768px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 20px 20px;
}

.search {
    width: 748px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 1.5rem 1.75rem;
    border-radius: 25px;
    border: 1px solid rgba(255, 255, 255, 0.84);
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(9.5px);
    -webkit-backdrop-filter: blur(9.5px);

    -webkit-box-shadow: 5px 5px 7px #1c1d1f, -5px -5px 7px #222527;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.search input {
    flex: 1;
    border: none;
    font-size: 1.5rem;
    font-family: var(--font-raleway);
    font-weight: 500;
    padding-right: 1rem;

    outline: none;
    color: #343434;
    background: rgba(255, 255, 255, 0);
}

.search img {
    width: 35px;
    height: 35px;
    cursor: pointer;
}

@media screen and (max-width: 860px) {
    .container {
        width: 600px;
    }

    .search {
        width: 600px;

    }
}

@media screen and (max-width: 710px) {
    .container {
        width: 500px;
    }

    .search {
        width: 500px;

    }
}

@media screen and (max-width: 615px) {
    .container {
        width: 400px;
    }

    .search {
        width: 400px;

    }
}

@media screen and (max-width: 515px) {
    .container {
        width: 300px;
    }

    .search {
        width: 300px;

    }
}

@media screen and (max-width: 390px) {
    .container {
        width: 240px;
    }

    .search {
        width: 240px;
        margin-bottom: 5px;
        padding: 15px 25px;
    }
    .search img {
        width: 20px;
        height: 20px;
    }
    .search input {
        font-size: 1.1rem;
        padding-right: 1rem;

    }
}

@media screen and (max-width: 290px) {
    .container {
        width: 210px;
    }

    .search {
        width: 210px;
        margin-bottom: 5px;
        padding: 15px 25px;

    }
    .search img {
        width: 25px;
        height: 25px;
    }
    .search input {
        font-size: 1.1rem;
        padding-right: 1rem;

    }
}