.container {
    width: 768px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 20px 20px;
}

.flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.flex-item {
    margin-right: 10px;
}

h2, h3, p {
    margin-top: 5px;
    margin-bottom: 5px;
}

.audio-icon {
    margin: 2px;
}

.audio-icon:hover {
    margin: 0px;
    fill: #6c6c6c;
    height: 33px;
    width: 33px;
}

.audio-icon-active {
    margin: 0px;
    fill: #6c6c6c;
    height: 33px;
    width: 33px;
}

@media screen and (max-width: 860px) {
    .container {
        width: 610px;
    }
}

@media screen and (max-width: 710px) {
    .container {
        width: 510px;
    }
}

@media screen and (max-width: 615px) {
    .container {
        width: 410px;
    }
}

@media screen and (max-width: 515px) {
    .container {
        width: 300px;
    }
}

@media screen and (max-width: 390px) {
    .container {
        width: 240px;
    }
}

@media screen and (max-width: 290px) {
    .container {
        width: 210px;
    }
}