.container {
    width: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}

h2, h3, p {
    margin-top: 5px;
    margin-bottom: 5px;
}

.capitalize:first-letter {
        text-transform:capitalize;
}